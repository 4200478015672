import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";

import { pages } from "components";
import Footer from "components/Layout/Footer";
import Navbar from "components/Layout/Navbar";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const NotFound = () => {
  return (
    <div className="w-full text-center py-32 space-y-8 text-white">
      <div>
        <h1 className="text-5xl font-bold">Oops!</h1>
        <h1 className="text-white text-2xl font-bold">Page Not Found</h1>
      </div>
      <h1 className="text-2xl italic">
        Try one of the links above to get back
      </h1>
    </div>
  );
};

function App() {
  return (
    <main className="bg-[url('../public/map-pattern.svg')]">
      <Router>
        <ScrollToTop />
        <Navbar pages={pages} />
        <div className="mx-auto min-h-screen max-w-screen-lg">
          <Routes>
            {pages.map(({ path, Component }) => {
              return <Route path={path} element={<Component />} />;
            })}
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </main>
  );
}

export default App;
