import Classes from "./Classes";
import College from "./College";

export default function Education() {
  return (
    <div>
      <College />
      <Classes />
    </div>
  );
}
